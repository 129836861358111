import Vue from 'vue'
import App from './App.vue'
import vuetify from "@/plugins/vuetify";
import router from "./router";
import axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import Multiselect from 'vue-multiselect'

Vue.config.productionTip = false

Vue.use( CKEditor );
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(VueSidebarMenu);
Vue.component('multiselect', Multiselect);


Vue.prototype.$http = axios;
// Vue.prototype.$baseUrl = 'https://grajagan.studiokami.co.id/grajagan/public';
// Vue.prototype.$api = 'https://grajagan.studiokami.co.id/grajagan/public/api';
Vue.prototype.$baseUrl = 'http://127.0.0.1:8000';
Vue.prototype.$api = 'http://127.0.0.1:8000/api';


new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app')