import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function importComponent(path){
    return () => import(`../components/${path}.vue`);
}

const router = new VueRouter({
    mode: "history",
    routes:[
        {
            path: "",
            component: importComponent("Header"),
            children:[
                    //Landing Page
                {
                    path: "/",
                    name: "LandingPage",
                    meta: { title: 'LandingPage'},
                    component: importComponent("LandingPage"),
                    
                },
            ],
        },
        {
            path: "",
            component: importComponent("DashboardLayout"),
            children:[
                //Dashboard
                {
                    path: "/dashboard",
                    name: "Dashboard",
                    meta: { title: 'Dashboard'},
                    component: importComponent("Dashboard"),
                },
                //News Category
                {
                    path: "/newscategory",
                    name: "NewsCategory",
                    meta: { title: 'News Category'},
                    component: importComponent("DataAdmin/NewsCategory"),
                },
                //Gallery Category
                {
                    path: "/gallerycategory",
                    name: "GalleryCategory",
                    meta: { title: 'Gallery Category'},
                    component: importComponent("DataAdmin/GalleryCategory"),
                },
                  //User (admin)
                {
                    path: "/user",
                    name: "User",
                    meta: { title: 'User'},
                    component: importComponent("DataAdmin/User"),
                },
                    //Gallery
                {
                    path: "/gallery",
                    name: "Gallery",
                    meta: { title: 'Gallery'},
                    component: importComponent("DataAdmin/Gallery"),
                },
                     //News
                {
                    path: "/news",
                    name: "News",
                    meta: { title: 'News'},
                    component: importComponent("DataAdmin/News"),
                },
                //Video
                {
                    path: "/video",
                    name: "Video",
                    meta: { title: 'Video'},
                    component: importComponent("DataAdmin/Video"),
                },
                 //Testimonial
                {
                    path: "/testimonial",
                    name: "Testimonial",
                    meta: { title: 'Testimonial'},
                    component: importComponent("DataAdmin/Testimonial"),
                },
                 //Season
                {
                    path: "/season",
                    name: "Season",
                    meta: { title: 'Season'},
                    component: importComponent("DataAdmin/Season"),
                },
                  //Picture Schedule
                {
                    path: "/pictureschedule",
                    name: "PictureSchedule",
                    meta: { title: 'Picture Schedule'},
                    component: importComponent("DataAdmin/PictureSchedule"),
                },
                  //Rate Package
                {
                    path: "/ratepackage",
                    name: "RatePackage",
                    meta: { title: 'Rate Package'},
                    component: importComponent("DataAdmin/RatePackage"),
                },
                  //Package
                {
                    path: "/package",
                    name: "Package",
                    meta: { title: 'Package'},
                    component: importComponent("DataAdmin/Package"),
                },
                   //Image Package
                {
                    path: "/imagepackage",
                    name: "ImagePackage",
                    meta: { title: 'ImagePackage'},
                    component: importComponent("DataAdmin/ImagePackage"),
                },
                    //Detail Package
                {
                    path: "/detailpackage",
                    name: "DetailPackage",
                    meta: { title: 'DetailPackage'},
                    component: importComponent("DataAdmin/DetailPackage"),
                },

            ],
            
        },

         {
            path: "",
            component: importComponent("Header"),
            children:[
                    //Landing Page
                {
                    path: "/landingpage",
                    name: "LandingPage",
                    meta: { title: 'LandingPage', layout: "default"},
                    component: importComponent("LandingPage"),
                    
                },
                
                //Boat Schedule Page
                {
                    path: "/boatschedule",
                    name: "BoatSchedule",
                    meta: { title: 'Boat Schedule'},
                    component: importComponent("BoatSchedule"),
                },
                //Travel Insurance Page
                {
                    path: "/travelinsurance",
                    name: "TravelInsurance",
                    meta: { title: 'Travel Insurance'},
                    component: importComponent("TravelInsurance"),
                },
                //Speed Boat Page
                {
                    path: "/speedboat",
                    name: "SpeedBoat",
                    meta: { title: 'Speed Boat'},
                    component: importComponent("SpeedBoat"),
                },
                //Camp Meals Page
                {
                    path: "/campmeals",
                    name: "CampMeals",
                    meta: { title: 'Camp Meals'},
                    component: importComponent("CampMeals"),
                },
                //About Page
                {
                    path: "/about",
                    name: "About",
                    meta: { title: 'About'},
                    component: importComponent("About"),
                },
                //Boat Itineraries Page
                {
                    path: "/boatitineraries",
                    name: "BoatItineraries",
                    meta: { title: 'Boat Itineraries'},
                    component: importComponent("BoatItineraries"),
                },
                //Overland Itineraries Page
                {
                    path: "/overlanditineraries",
                    name: "OverlandItineraries",
                    meta: { title: 'Overland Itineraries'},
                    component: importComponent("OverlandItineraries"),
                },
                //Overland Itineraries Page
                {
                    path: "/surffaq",
                    name: "SurfFaq",
                    meta: { title: 'Surf Faq'},
                    component: importComponent("SurfFaq"),
                },
                 //Detail News
                {
                    path: "/detailnews/:id",
                    name: "DetailNews",
                    meta: { title: 'Detail News'},
                    component: importComponent("DetailNews"),
                },
                //News Page
                {
                    path: "/newspage",
                    name: "NewsPage",
                    meta: { title: 'News Page'},
                    component: importComponent("NewsPage"),
                },
                 //Gallery Page
                {
                    path: "/gallerypage",
                    name: "GalleryPage",
                    meta: { title: 'Gallery Page'},
                    component: importComponent("GalleryPage"),
                },
                  //Video Page
                {
                    path: "/videopage",
                    name: "VideoPage",
                    meta: { title: 'Video Page'},
                    component: importComponent("VideoPage"),
                },
      
                //Video Page
                {
                    path: "/detailvideo/:id",
                    name: "DetailVideo",
                    meta: { title: 'Detail Video'},
                    component: importComponent("DetailVideo"),
                },
                   //Detail Profile Customer
                {
                    path: "/detailcustomer/:id",
                    name: "DetailProfileCustomer",
                    meta: { title: 'Profile Customer'},
                    component: importComponent("DetailProfileCustomer"),
                },
                    //SeasonPage
                {
                    path: "/seasonpage",
                    name: "SeasonPage",
                    meta: { title: 'Season Page'},
                    component: importComponent("SeasonPage"),
                },
                    //PackagePage
                {
                    path: "/packagepage/:id",
                    name: "PagePackage",
                    meta: { title: 'Package Page'},
                    component: importComponent("PagePackage"),
                },
      

            ],
            
        },
        
        //Login Customer
        {
            path: "/login",
            name: "Login",
            meta: { title: 'Login'},
            component: importComponent("Login"),
        },
        //Login Page Admin
        {
            path: "/loginadmin",
            name: "LoginAdmin",
            meta: { title: 'Login Admin Page'},
            component: importComponent("LoginAdmin"),
        },

        //Register
        {
            path: "/register",
            name: "Register",
            meta: { title: 'Register'},
            component: importComponent("Register"),
        },
        {
            path: '*',
            name: "Page404",
            meta: { title: '404 Not Found'},
            component: importComponent("Page404"),
        },
    ],
});

router.beforeEach((to, from, next) =>{


    //  //Customer
    // if(
    //     to.name == 'Gallery' && localStorage.getItem("customer_id") != '' && localStorage.getItem('user_id') != '' ||
    //     to.name == 'GalleryCategory' && localStorage.getItem("customer_id") != '' && localStorage.getItem('user_id') != '' ||
    //     to.name == 'News' && localStorage.getItem("customer_id") != '' && localStorage.getItem('user_id') != '' ||
    //     to.name == 'NewsCategory' && localStorage.getItem("customer_id") != '' && localStorage.getItem('user_id') != '' ||
    //     to.name == 'Testimonial' && localStorage.getItem("customer_id") != '' && localStorage.getItem('user_id') != '' ||
    //     to.name == 'User' && localStorage.getItem("customer_id") != '' && localStorage.getItem('user_id') != '' ||
    //     to.name == 'Video' && localStorage.getItem("customer_id") != '' && localStorage.getItem('user_id') != ''
    // ){
    //      next({
    //         name: "LandingPage"
    //     })
    // }

    //  //Customer sudah login
    // if(
    //     to.name == 'Gallery' && localStorage.getItem("customer_id") != '' && localStorage.getItem('customer_email') != '' ||
    //     to.name == 'GalleryCategory' && localStorage.getItem("customer_id") != '' && localStorage.getItem('customer_email') != '' ||
    //     to.name == 'News' && localStorage.getItem("customer_id") != '' && localStorage.getItem('customer_email') != '' ||
    //     to.name == 'NewsCategory' && localStorage.getItem("customer_id") != '' && localStorage.getItem('customer_email') != '' ||
    //     to.name == 'Testimonial' && localStorage.getItem("customer_id") != '' && localStorage.getItem('customer_email') != '' ||
    //     to.name == 'User' && localStorage.getItem("customer_id") != '' && localStorage.getItem('customer_email') != '' ||
    //     to.name == 'Video' && localStorage.getItem("customer_id") != '' && localStorage.getItem('customer_email') != ''
    // ){
    //      next({
    //         name: "LandingPage"
    //     })
    // }

    // if(to.name == 'DetailProfileCustomer' && localStorage.getItem("customer_id") == '' && localStorage.getItem('customer_email') == ''){
    //       next({
    //         name: "LandingPage"
    //     })
    // }


    //Admin
    if(
        to.name == 'Gallery' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin'  ||
        to.name == 'GalleryCategory' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'News' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin'||
        to.name == 'NewsCategory' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'Testimonial' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'User' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'Video' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'Season' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'PictureSchedule' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'RatePackage' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'Package' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin' ||
        to.name == 'ImagePackage' && localStorage.getItem("user_id") != '' && localStorage.getItem('user_role') != 'Admin' && localStorage.getItem('user_role') != 'SuperAdmin'
    ){
         next({
            name: "LandingPage"
        })
    }

  document.title = to.meta.title;
  next();
});
export default router